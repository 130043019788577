<template>
  <el-aside
      :class="isCollapse ? 'hide-aside' : 'show-side'"
      :width="isCollapse ? '46px' : '250px'"
  >
    <div>
      <div
          class="burger-expand mask menu-icon"
          v-show="!isCollapse"
          @click="toggleSidebarView"
      >
        <el-icon>
          <Fold/>
        </el-icon>
      </div>
      <div
          v-show="isCollapse"
          style="min-height: 30px;"
      >
        <p></p>
      </div>
      <!--    <div-->
      <!--        class="logo"-->
      <!--    >-->
      <!--      <img src="@/assets/logo.png" style="width: auto;height: auto" />-->
      <!--    </div>-->
    </div>
    <!--    <div class="left-menu-burger">-->
    <!--      <el-button-->
    <!--        v-if="isCollapse"-->
    <!--        @click="toggleSidebarView"-->
    <!--      >-->
    <!--        c-->
    <!--      </el-button>-->
    <!--      <el-button-->
    <!--        v-if="!isCollapse"-->
    <!--        @click="toggleSidebarView"-->
    <!--      >-->
    <!--        <el-icon><Menu/></el-icon>-->
    <!--      </el-button>-->
    <!--    </div>-->
    <!--    <div-->
    <!--      v-if="!isCollapse"-->
    <!--      class="logo"-->
    <!--    >-->
    <!--      [LOGO]-->
    <!--    </div>-->
    <div class="menu-top">
      <SuperAdmin v-if="is('super-admin')"></SuperAdmin>
      <Realtor v-if="is('realtor')"></Realtor>
      <Designer v-if="is('designer')"></Designer>
      <Others v-if="is('editor')"></Others>
    </div>
  </el-aside>
</template>

<script>
import {useStore} from "vuex";
import UserService from "@/api/user.service";
import DisclaimerService from "@/api/disclaimer.service";
import SuperAdmin from "@/layout/components/Sidebar/SuperAdmin";
import Realtor from "@/layout/components/Sidebar/Realtor";
import Designer from "@/layout/components/Sidebar/Designer";
import Others from "@/layout/components/Sidebar/Others";
import {ElIcon, ElButton} from 'element-plus'
import {useEventListener, useResizeObserver} from "@vueuse/core";

export default {
  name: "Sidebar",
  components: {Others, Realtor, Designer, SuperAdmin, ElIcon, ElButton},
  computed: {
    isCollapse() {
      // console.log(this.store.state.app.isCollapse)
      return this.store.state.app.isCollapse
    }
  },
  data() {
    return {
      store: useStore(),
      // isCollapse: false,
      // width: '225px'
    }
  },
  methods: {
    async setAccountUrl() {
      return UserService.getUserBoard().then(response => {
        this.$router.push({path: "/account/" + response.data.id});
      });
    },
    async setChangepasswordUrl() {
      return UserService.getUserBoard().then(response => {
        this.$router.push({path: "/account/" + response.data.id, query: {iflogin: 'y'}});
      });
    },

    async setMlsboardUrl() {
      return UserService.getUserBoard().then(response => {
        this.$router.push({path: "/mlsboard/" + response.data.id});
      });
    },
    async setSettingUrl() {
      return UserService.getUserBoard().then(response => {
        this.$router.push({path: "/setting/" + response.data.domains[0].id});
      });
    },
    async setDisclaimer() {
      return DisclaimerService.disclaimer(1, 1).then(response => {
        this.$router.push({path: "/listingdisclaimer/" + response.data.data[0].id});
      });
    },
    toggleSidebarView() {
      // this.isCollapse = !this.isCollapse

      this.store.commit("app/isCollapseChange", true);
      // this.width = '210px'
      // if(this.isCollapse) {
      //     this.width = '45px'
      // }
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    handleSelect(key, keyPath) {
      this.handleClose(key, keyPath)
    },
  }

}
</script>

<style lang="scss" scoped>
@import '../../../styles/variables.scss';

.menu-top {
  margin-top: 15px;
  z-index: 999;
}

.el-aside {
  //color: #fff;
  text-align: left;
  padding: 0;
  //background: transparent;

  .menu-icon {
    width: 60px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 34px;
    font-weight: 100;
    cursor: pointer;
    margin-right: 10px;
    /*&:hover {*/
    /*background-color: var(--system-header-item-hover-color);*/
    /*}*/
    /*i {*/
    /*  color: var(--system-header-text-color);*/
    /*}*/
  }
}

.burger-expand {
  font-size: 34px;
  margin: 6px 25px;
  width: 30px;
}

.el-menu, .el-submenu, .el-menu-item, .element-menu-item {
  height: $sideBarIemHeight;
  background: transparent !important;
  color: $defaultText;
  margin-top: 16px;
  margin-left: 1px;
}

.el-menu > li > * {
  color: $defaultText !important;
}

.el-menu > .el-submenu > div > span {
  color: $defaultText !important;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 210px;
  min-height: 400px;
}

.el-icon-menu {
  color: $defaultText;
}

.left-menu-burger {
  text-align: left;
  margin-bottom: 0;
  margin-top: 5px;
  position: relative;
  display: inline-flex;
  margin-left: 7px;
}

.i-style-sidebar {
  width: 25px;
  margin-left: 5px;
}

.logo {
  font-weight: bold;
  color: #000;
  position: relative;
  display: inline-flex;
  width: 100px;
  margin-left: 90px;
  margin-top: -49px;
}

.el-header {
  padding-left: 0;
  padding-right: 0;
}

.el-aside {
  display: flex;
  flex-direction: column;
  transition: 0.2s;
  overflow-x: hidden;
  transition: 0.3s;

  &::-webkit-scrollbar {
    width: 0 !important;
  }
}

.el-main {
  background-color: var(--system-container-background);
  height: 100%;
  padding: 0;
  overflow-x: hidden;
}

:deep(.el-main-box) {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

@media screen and (max-width: 1000px) {
  .menu-top {
    margin-top: 50px;
  }
  .el-aside {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 1000;

    &.hide-aside {
      left: -250px;
    }
  }
  .burger-expand {
    font-size: 34px;
    margin: 0px 0px;
  }
  .mask {
    position: fixed;
    top: 44px;
    left: 25px;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    //background: rgba(0, 0, 0, 0.5);
  }
  .logo {
    background-color: #0b4e85;
    margin-left: 90px;
    margin-top: 60px;
  }
}
</style>
