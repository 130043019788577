import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import NotFound from '@/components/NotFound'
import Layout from '@/layout/MainLayout'

const routes = [
    {
        path: '/register',
        component: () => import('@/views/Register'),
        hidden: true
    },
  {
    path: '/login',
    component: () => import('@/views/Login'),
    hidden: true
  },
  {
    path: '/logout',
    component: () => import('@/views/Logout'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'dashboard',
        component: () => import('@/views/Dashboard'),
        name: 'Dashboard',
        meta: { title: 'Dashboard', icon: 'dashboard', affix: true }
      },
      {
        path: 'leads',
        component: () => import('@/views/Leads'),
        name: 'Leads',
        meta: { title: 'Leads', icon: 'leads', affix: true }
      },
      {
        path: 'lead/add',
        component: () => import('@/views/Leads/add.vue'),
        name: 'addLead',
        meta: { title: 'Add Lead', icon: 'leads', affix: true }
      },
      {
        path: 'lead/edit/:leadid',
        component: () => import('@/views/Leads/add.vue'),
        name: 'editLead',
        meta: { title: 'Edit Lead', icon: 'leads', affix: true }
      },
      {
        path: 'lead/exportleads',
        component: () => import('@/views/Leads/exportleads.vue'),
        name: 'exportLeads',
        meta: { title: 'Export Leads', icon: 'leads', affix: true }
      },
      {
        path: 'lead/importleads',
        component: () => import('@/views/Leads/importleads.vue'),
        name: 'importLeads',
        meta: { title: 'Import Leads', icon: 'leads', affix: true }
      },
      {
        path: 'customerprofile/:customer_id',
        component: () => import('@/views/Customer/index.vue'),
        name: 'customerprofile',
        meta: { title: 'Customer profile', icon: 'Customer Profile', affix: true }
      },
      {
        path: 'news',
        component: () => import('@/views/News'),
        name: 'News',
        meta: { title: 'News', icon: 'leads', affix: true }
      },
      {
        path: 'news/add',
        component: () => import('@/views/News/add.vue'),
        name: 'addNews',
        meta: { title: 'Add News', icon: 'leads', affix: true }
      },
      {
        path: 'news/edit/:newsid',
        component: () => import('@/views/News/add.vue'),
        name: 'editNews',
        meta: { title: 'Edit News', icon: 'leads', affix: true }
      },
      {
        path: 'lead/exportleads',
        component: () => import('@/views/Leads/exportleads.vue'),
        name: 'exportLeads',
        meta: { title: 'Export Leads', icon: 'leads', affix: true }
      },
      {
        path: 'lead/importleads',
        component: () => import('@/views/Leads/importleads.vue'),
        name: 'importLeads',
        meta: { title: 'Import Leads', icon: 'leads', affix: true }
      },
      {
        path: 'listings',
        component: () => import('@/views/Listings'),
        name: 'Listings',
        meta: { title: 'Listings', icon: 'listings', affix: true }
      },
     {
        path: 'listing/uploadimage',
        component: () => import('@/views/Listings/uploadimage.vue'),
        name: 'Upload Image',
        meta: { title: 'Upload Image', icon: 'Upload Image', affix: true }
      },
      {
        path: 'listing/add',
        component: () => import('@/views/Listings/addlisting.vue'),
        name: 'adeditListing',
        meta: { title: 'add Listing', icon: 'Add Listing', affix: true }
      },
      {
        path: 'listing/edit/:listingId',
        component: () => import('@/views/Listings/addlisting.vue'),
        name: 'editListing',
        meta: { title: 'Edit Listing', icon: 'Edit Listing', affix: true }
      },
      {
        path: 'listing/uploadpdf',
        component: () => import('@/views/Listings/uploadpdf.vue'),
        name: 'Upload PDF',
        meta: { title: 'Upload PDF', icon: 'Upload PDF', affix: true }
      },
      {
        path: 'blog',
        component: () => import('@/views/Blog'),
        name: 'Blogs',
        meta: { title: 'Blogs', icon: 'Blogs', affix: true }
      },
      {
        path: 'blog/uploadimage',
        component: () => import('@/views/Blog/uploadimage.vue'),
        name: 'Upload Image',
        meta: { title: 'Upload Image', icon: 'Upload Image', affix: true }
      },
      {
        path: 'blog/add',
        component: () => import('@/views/Blog/addblog.vue'),
        name: 'addBlogs',
        meta: { title: 'add Blog', icon: 'Add Blog', affix: true }
      },
      {
        path: 'blog/edit/:blogId',
        component: () => import('@/views/Blog/addblog.vue'),
        name: 'editBlog',
        meta: { title: 'Edit Blog', icon: 'Edit Blog', affix: true }
      },
      {
        path: 'gallery',
        component: () => import('@/views/Gallery'),
        name: 'Gallery',
        meta: { title: 'Gallery', icon: 'Gallery', affix: true }
      },
      {
        path: 'gallery/add',
        component: () => import('@/views/Gallery/addgallery.vue'),
        name: 'addGallery',
        meta: { title: 'Gallery', icon: 'Gallery', affix: true }
      },
      {
        path: 'gallery/edit/:galleryId',
        component: () => import('@/views/Gallery/addgallery.vue'),
        name: 'editGallery'
      },
     {
        path: 'building',
        component: () => import('@/views/Building'),
        name: 'Buildings',
        meta: { title: 'Buildings', icon: 'Buildings', affix: true }
      },
      {
        path: 'building/uploadimage',
        component: () => import('@/views/Building/uploadimage.vue'),
        name: 'Upload Image',
        meta: { title: 'Upload Image', icon: 'Upload Image', affix: true }
      },
      {
        path: 'building/add',
        component: () => import('@/views/Building/addbuilding.vue'),
        name: 'addBuilding',
        meta: { title: 'add Building', icon: 'Add Building', affix: true }
      },
      {
        path: 'building/edit/:buildingId',
        component: () => import('@/views/Building/addbuilding.vue'),
        name: 'editBuilding',
        meta: { title: 'Edit building', icon: 'Edit building', affix: true }
      },
	{
        path: 'information',
        component: () => import('@/views/Information'),
        name: 'Information',
        meta: { title: 'Information', icon: 'Information', affix: true }
      },
      {
        path: 'pages',
        component: () => import('@/views/Information/pages.vue'),
        name: 'Pages',
        meta: { title: 'Web Pages', icon: 'Web Pages', affix: true }
      },
      {
        path: 'page/add',
        component: () => import('@/views/Information/addpage.vue'),
        name: 'addPage',
        meta: { title: 'Page', icon: 'Page', affix: true }
      },
      {
        path: 'information/add',
        component: () => import('@/views/Information/addinformation.vue'),
        name: 'addInformation',
        meta: { title: 'Information', icon: 'Information', affix: true }
      },
      {
        path: 'page/edit/:informationId',
        component: () => import('@/views/Information/addpage.vue'),
        name: 'editPage'
      },
      {
        path: 'information/edit/:informationId',
        component: () => import('@/views/Information/addinformation.vue'),
        name: 'editInformation'
      },
	{
        path: 'testimonial',
        component: () => import('@/views/Testimonials'),
        name: 'Testimonial',
        meta: { title: 'Testimonials', icon: 'Testimonials', affix: true }
      },
      {
        path: 'testimonial/add',
        component: () => import('@/views/Testimonials/addtestimonial.vue'),
        name: 'addTestimonial',
        meta: { title: 'Testimonials', icon: 'Testimonials', affix: true }
      },
      {
        path: 'testimonial/edit/:testimonialId',
        component: () => import('@/views/Testimonials/addtestimonial.vue'),
        name: 'editTestimonial'
      },
      {
        path: 'community',
        component: () => import('@/views/Community'),
        name: 'Communities',
        meta: { title: 'Communities', icon: 'Communities', affix: true }
      },
      {
        path: 'community/add',
        component: () => import('@/views/Community/addcommunity.vue'),
        name: 'addcommunity',
        meta: { title: 'Add Community', icon: 'Add Community', affix: true }
      },
      {
        path: 'community/edit/:communityId',
        component: () => import('@/views/Community/addcommunity.vue'),
        name: 'editCommunity'
      },
      {
        path: 'menu',
        component: () => import('@/views/Menu'),
        name: 'Menu',
        meta: { title: 'Menu', icon: 'Menu', affix: true }
      },
      {
        path: 'menu/add',
        component: () => import('@/views/Menu/addmenu.vue'),
        name: 'addmenu',
        meta: { title: 'Add Menu', icon: 'Add Menu', affix: true }
      },
      {
        path: 'menu/edit/:menuId',
        component: () => import('@/views/Menu/addmenu.vue'),
        name: 'editmenu'
      },
      {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
      },
      {
        path: 'myteam',
        component: () => import('@/views/Myteam/index.vue'),
        name: 'my-team',
        meta: { title: 'My Team', icon: 'My Team', affix: true }
      },
      {
        path: 'myteam/edit/:myteam',
        component: () => import('@/views/Myteam/add.vue'),
        name: 'editmyteam',
        meta: { title: 'Edit My Team', icon: 'Edit My Team', affix: true }
      },
      {
        path: 'myteam/add',
        component: () => import('@/views/Myteam/add.vue'),
        name: 'addmyteam',
        meta: { title: 'Add My Team', icon: 'Add My Team', affix: true }
      },
      {
        path: 'ddfgroup',
        component: () => import('@/views/Ddfgroup/index.vue'),
        name: 'ddfgroup',
        meta: { title: 'Ddf group', icon: 'Ddf group', affix: true }
      },
      {
        path: 'ddfgroup/edit/:groupId',
        component: () => import('@/views/Ddfgroup/addgroup.vue'),
        name: 'editGroup',
        meta: { title: 'Edit ddf group', icon: 'Edit ddf group', affix: true }
      },
      {
        path: 'ddfgroup/add',
        component: () => import('@/views/Ddfgroup/addgroup.vue'),
        name: 'adddfgroup',
        meta: { title: 'Add ddf group', icon: 'Add ddf group', affix: true }
      },
      {
        path: 'setting/:DomainId',
        component: () => import('@/views/Setting'),
        name: 'Settings',
        meta: { title: 'Settings', icon: 'Settings', affix: true }
      },
      {
        path: 'profile',
        component: () => import('@/views/Profile'),
        name: 'Profile',
        meta: { title: 'Profile', icon: 'Profile', affix: true }
      },
      {
        path: 'account',
        component: () => import('@/views/Account'),
        name: 'Account',
        meta: { title: 'Account', icon: 'Account', affix: true }
      },
      {
        path: 'customers',
        component: () => import('@/views/Customers'),
        name: 'Customers',
        meta: { title: 'Customers', icon: 'Customers', affix: true }
      },     
      {
        path: 'billing',
        component: () => import('@/views/Billing'),
        name: 'Billing',
        meta: { title: 'Billing', icon: 'Money', affix: true }
      },
      {
        path: 'invoices',
        component: () => import('@/views/Invoices'),
        name: 'Invoices',
        meta: { title: 'Invoices', icon: 'Invoices', affix: true }
      },
      {
        path: 'mlsboard/:realtorid',
        component: () => import('@/views/Mlsboard'),
        name: 'Mlsboard',
        meta: { title: 'Mlsboard', icon: 'Mlsboard', affix: true }
      },
      {
        path: 'domains',
        component: () => import('@/views/Domains'),
        name: 'domains',
        meta: { title: 'domains', icon: 'domains', affix: true }
      },
      {
        path: 'custom-style',
        component: () => import('@/views/Customstyle'),
        name: 'Customstyle',
        meta: { title: 'Customstyle', icon: 'Customstyle', affix: true }
      },
      {
        path: 'setting-images',
        component: () => import('@/views/Images'),
        name: 'Images',
        meta: { title: 'Images', icon: 'Images', affix: true }
      },
      
      {
        path: 'listingdisclaimer',
        component: () => import('@/views/Listingdisclaimer'),
        name: 'listingdisclaimer',
        meta: { title: 'Listing disclaimer', icon: 'user', affix: true }
      },
      {
        path: 'listingdisclaimer/:disclaimerId',
        component: () => import('@/views/Listingdisclaimer'),
        name: 'editlistingdisclaimer',
        meta: { title: 'Edit Listing disclaimer', icon: 'user', affix: true }
      },
		{
		path: 'banners',
		component: () => import('@/views/Banners/'),
		name: 'banners',
		meta: { title: 'Banners', icon: 'Banners', affix: true }
		},
		{
		path: 'banner/edit/:bannerid',
		component: () => import('@/views/Banners/add.vue'),
		name: 'banneredit',
		meta: { title: 'Edit Banner', icon: 'Edit Banner', affix: true }
		},
		{
		path: 'banner/add',
		component: () => import('@/views/Banners/add.vue'),
		name: 'addbanner',
		meta: { title: 'Add Banner', icon: 'Add Banner', affix: true }
		},
    {
      path: 'template',
      component: () => import('@/views/Template/'),
      name: 'Template',
      meta: { title: 'Template', icon: 'Template', affix: true }
    },	
    {
      path: 'template/setting',
      component: () => import('@/views/Template/setting.vue'),
      name: 'Template Setting',
      meta: { title: 'Template Setting', icon: 'Template Setting', affix: true }
    },	
    {
      path: 'theme/skin',
      component: () => import('@/views/Theme/setting.vue'),
      name: 'Theme Setting',
      meta: { title: 'Theme Setting', icon: 'Theme Setting', affix: true }
    },
	{
      path: 'theme/skin/custom',
      component: () => import('@/views/Theme/customsetting.vue'),
      name: 'Custom Theme/Skin',
      meta: { title: 'Custom Theme/Skin', icon: 'Custom Theme/Skin', affix: true }
    },
    {
      path: 'template/edit/:templateId',
      component: () => import('@/views/Template/add.vue'),
      name: 'templateedit',
      meta: { title: 'Edit Template', icon: 'Edit Template', affix: true }
      },
      {
      path: 'template/add',
      component: () => import('@/views/Template/add.vue'),
      name: 'addtemplate',
      meta: { title: 'Add Template', icon: 'Add Template', affix: true }
      },
    {
      path: 'forms',
      component: () => import('@/views/Forms/'),
      name: 'Forms',
      meta: { title: 'Forms', icon: 'Forms', affix: true }
    },	
    {
      path: 'forms/edit/:moduleId',
      component: () => import('@/views/Forms/add.vue'),
      name: 'formsedit',
      meta: { title: 'Edit Form', icon: 'Edit Form', affix: true }
      },
      {
      path: 'forms/add',
      component: () => import('@/views/Forms/add.vue'),
      name: 'addforms',
      meta: { title: 'Add Form', icon: 'Add Form', affix: true }
      },
    {
      path: 'user/modules',
      component: () => import('@/views/Users/Modules/'),
      name: 'User Modules',
      meta: { title: 'User Modules', icon: 'Modules', affix: true }
    },	
    {
      path: 'user/modules/edit/:moduleId',
      component: () => import('@/views/Users/Modules/add.vue'),
      name: 'usermodulesedit',
      meta: { title: 'Edit User Module', icon: 'Edit Module', affix: true }
      },
      {
      path: 'user/modules/add',
      component: () => import('@/views/Users/Modules/add.vue'),
      name: 'usertaddmodules',
      meta: { title: 'Add User Module', icon: 'Add Module', affix: true }
      },
    {
      path: 'modules',
      component: () => import('@/views/Modules/'),
      name: 'Modules',
      meta: { title: 'Modules', icon: 'Modules', affix: true }
    },	
    {
      path: 'modules/edit/:moduleId',
      component: () => import('@/views/Modules/add.vue'),
      name: 'modulesedit',
      meta: { title: 'Edit Module', icon: 'Edit Module', affix: true }
      },
      {
      path: 'modules/add',
      component: () => import('@/views/Modules/add.vue'),
      name: 'addmodules',
      meta: { title: 'Add Module', icon: 'Add Module', affix: true }
      },
      {
        path: 'product/add',
        component: () => import('@/views/Product/add.vue'),
        name: 'addproduct',
        meta: { title: 'Add Product', icon: 'Add Product', affix: true }        
      },
      {
        path: 'product/edit/:productId',
        component: () => import('@/views/Product/add.vue'),
        name: 'editproduct',
        meta: { title: 'Edit Product', icon: 'Edit Product', affix: true }        
      },
      {
        path: 'products',
        component: () => import('@/views/Product/'),
        name: 'products',
        meta: { title: 'Products', icon: 'Products', affix: true }        
      },
      {
        path: 'price/add',
        component: () => import('@/views/Price/add.vue'),
        name: 'addprice',
        meta: { title: 'Add Price', icon: 'Add Price', affix: true }        
      },
      {
        path: 'price/edit/:priceId',
        component: () => import('@/views/Price/add.vue'),
        name: 'editprice',
        meta: { title: 'Edit Price', icon: 'Edit Price', affix: true }        
      },
      {
        path: 'prices',
        component: () => import('@/views/Price/'),
        name: 'Price',
        meta: { title: 'Price', icon: 'Price', affix: true }        
      },
      {
        path: 'roles/add',
        component: () => import('@/views/Roles/add.vue'),
        name: 'addroles',
        meta: { title: 'Add Roles', icon: 'Add Roles', affix: true }        
      },
      {
        path: 'roles/edit/:rolesId',
        component: () => import('@/views/Roles/add.vue'),
        name: 'editroles',
        meta: { title: 'Edit Roles', icon: 'Edit Roles', affix: true }        
      },
      {
        path: 'roles',
        component: () => import('@/views/Roles/'),
        name: 'Roles',
        meta: { title: 'Roles', icon: 'Roles', affix: true }        
      },
      {
        path: 'permissions/add',
        component: () => import('@/views/Permissions/add.vue'),
        name: 'addpermission',
        meta: { title: 'Add Permission', icon: 'Add Permission', affix: true }        
      },
      {
        path: 'permissions/edit/:permissionId',
        component: () => import('@/views/Permissions/add.vue'),
        name: 'editpermission',
        meta: { title: 'Edit Permission', icon: 'Edit Permission', affix: true }        
      },
      {
        path: 'permissions',
        component: () => import('@/views/Permissions/'),
        name: 'Permissions',
        meta: { title: 'Permissions', icon: 'Permissions', affix: true }        
      },
      {
        path: 'users/add',
        component: () => import('@/views/Users/add.vue'),
        name: 'addusers',
        meta: { title: 'Add Users', icon: 'Add Users', affix: true }        
      },
      {
        path: 'users/edit/:usersId',
        component: () => import('@/views/Users/add.vue'),
        name: 'editusers',
        meta: { title: 'Edit Users', icon: 'Edit Users', affix: true }        
      },
      {
        path: 'users',
        component: () => import('@/views/Users/'),
        name: 'Users',
        meta: { title: 'Users', icon: 'Users', affix: true }        
      },
    ]
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,


  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// navigation guard to check for logged in users
// router.beforeEach((to, from, next) => {
//   const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  // if (requiresAuth && !auth.currentUser) {
  //   next('/login')
  // } else {
  //   next()
  // }
// })

router.beforeEach((to, from, next) => {
  // const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  // const publicPages = ['/login', '/register', '/forgot-password'];
  // const authRequired = !publicPages.includes(to.path);
  const authRequired = to.matched.some(x => x.meta.requiresAuth)
  const loggedIn = localStorage.getItem('user');
  if(loggedIn) {
      let user = JSON.parse(loggedIn)
      window.Laravel = {
          csrfToken: user.csrf,
          jsPermissions: user.permissions
      }

  }
  // const access_token = store.getters.access_token;
  // const hasRoles = store.getters.roles && store.getters.roles.length > 0

  // trying to access a restricted page + not logged in
  // redirect to login page
  // if (authRequired && (!loggedIn || !access_token)) {
  if (authRequired && (!loggedIn)) {
    next('/login');
  } else {
    next();
  }
});


export default router
